<template>
  <v-container fluid> </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    activateUser() {
      this.$store.dispatch('misc/setLoading', {
        status: true,
        text: 'Activating User. Please Wait...',
      });
      const token = this.$route.params.token;
      this.$store
        .dispatch('activateUserWithToken', token)
        .then(response => {
          this.$store.dispatch('misc/setSnack', {
            text: response.data.msg,
            color: 'success',
          });
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch('misc/setSnack', {
            text: error.response.data.msg,
            color: 'error',
          });
        })
        .finally(() => {
          this.$store.dispatch('misc/setLoading', false);
        });
    },
  },
  computed: {},
  created() {
    let token = this.$route.params.token;
    // check if activation token has not been used before
    this.$store
      .dispatch('getActivateTokenValid', token)
      .then(response => {
        if (!response.data.valid) {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        } else {
          this.activateUser();
        }
      })
      .catch(error => {
        this.$store.dispatch('misc/setLoading', false);
        var snack = {
          text: error.response.data.msg,
          color: 'error',
        };
        this.$store.dispatch('misc/setSnack', snack);
      });
  },
};
</script>

<style></style>
